<template>
  <div>
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:[`item.case`]="{ item }">
        {{ item.case.case_id }}
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div>
          {{ item.case.client.name }}
          <div v-if="item.case.client.user.email">
            {{ item.case.client.user.email }}
          </div>
          <div v-if="item.case.client.user.mobile">
            {{ item.case.client.user.mobile_display }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.mode`]="{ item }">
        <div>
          <div class="mb-2" v-if="item.mode">
            {{ item.mode.name }}
          </div>
          <v-chip
            class="blue lighten-5 blue--text text--darken-2 font-weight-bold"
            small
          >
            {{ item.type_display }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <div
          v-if="item.is_additional"
          class="my-4 d-flex align-center justify-center"
        >
          <span class="mr-2" v-if="item.type === $keys.AUTOMATIC">
            ₹{{ item.payable_amount }}
          </span>
          <span class="mr-2" v-else>₹{{ item.total_amount }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="pointer">
                mdi-information
              </v-icon>
            </template>
            <div>
              <div>University Cost: ₹{{ item.university_cost }}</div>
              <div>
                FE/IR Cost: ₹{{ item.additional_university_cost }} +
                {{ item.gst_percent }}% tax
              </div>
              <div>
                Processing Cost: ₹{{ item.processing_cost }} +
                {{ item.gst_percent }}% tax
              </div>
              <div>
                Vendor Cost: ₹{{ item.additional_processing_cost }} +
                {{ item.gst_percent }}% tax
              </div>
              <div>Shipping Cost: ₹{{ item.shipping_cost }}</div>
              <div v-if="item.type === $keys.AUTOMATIC">
                Gateway Charges: ₹{{ item.gateway_charges }}
              </div>
            </div>
          </v-tooltip>
        </div>
        <div v-else>₹{{ item.amount }}</div>
      </template>

      <template v-slot:[`item.assigned_sales`]="{ item }">
        <div v-if="item.case.sales">
          {{ item.case.sales.name }}
          <div v-if="item.case.sales.email">
            {{ item.case.sales.email }}
          </div>
        </div>

        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.remarks`]="{ item }">
        <div class="my-4">
          <div v-if="item.remarks || item.is_additional">
            <div class="mb-2">
              <v-chip
                v-if="item.is_additional"
                class="orange lighten-5 orange--text text--darken-2 font-weight-bold"
                small
              >
                Additional
              </v-chip>
            </div>
            <div>{{ item.remarks }}</div>
          </div>
          <v-icon v-else>mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.document`]="{ item }">
        <div
          class="text-decoration-underline pointer primary--text"
          v-if="item.document"
          @click="viewDocuments(item)"
        >
          View Documents
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          class="lighten-5 text--darken-2 font-weight-bold text-capitalize"
          :class="
            item.status === 'successful'
              ? 'success success--text'
              : item.status === 'cancelled'
              ? 'error error--text'
              : 'blue blue--text'
          "
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:[`item.reject_reason`]="{ item }">
        <div v-if="item.reject_reason">
          {{ item.reject_reason }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status_date`]="{ item }">
        <div v-if="item.status_datetime">
          {{ $utils.formatDate(item.status_datetime) }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.payment_date`]="{ item }">
        <div v-if="item.payment_date">
          {{ $utils.formatDate(item.payment_date) }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="py-4 d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            height="32px"
            v-if="item.actions.includes('payment_receipt_download')"
            @click="downloadReceipt(item.id)"
          >
            <v-icon left>mdi-download</v-icon>
            Download Receipt
          </v-btn>

          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            height="32px"
            v-if="item.actions.includes('view_case')"
            @click="viewCase(item.case.id)"
          >
            View Case
          </v-btn>

          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            height="32px"
            @click="openConfirmationDialog(item, 'approve')"
            v-if="item.actions.includes('payment_approve')"
          >
            Approve
          </v-btn>

          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            height="32px"
            @click="openConfirmationDialog(item, 'reject')"
            v-if="item.actions.includes('payment_reject')"
          >
            Reject
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
            class="rounded-lg"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <ConfirmationDialog @confirmed="confirmed" @cancelled="cancelled">
      <template v-slot:confirmBtnText> Yes {{ confirmation_type }} </template>
      <template v-slot:form v-if="confirmation_type === 'reject'">
        <v-form lazy-validation ref="form" class="mb-2 mx-2">
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.reason_id"
                :items="reason_list"
                item-text="title"
                item-value="id"
                flat
                outlined
                dense
                label="Select Reason"
                :rules="[$rules.required]"
                class="rounded-lg"
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="form.reason_id === 0">
              <v-textarea
                flat
                :rows="3"
                outlined
                v-model="form.reason_other"
                label="Type here..."
                :rules="[$rules.required]"
                class="rounded-lg"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ConfirmationDialog>
    <document-viewer />
  </div>
</template>
<script>
import _ from "lodash";
import { saveAs } from "file-saver";
import { mapActions, mapGetters } from "vuex";

import DocumentViewer from "@/components/Dialogs/DocumentViewer";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

import { paymentTable } from "@/headers";

export default {
  components: { DocumentViewer, ConfirmationDialog },
  data() {
    return {
      items: [],
      loading: false,

      page_total: 1,
      page_number: 1,
      total_count: 0,
      page_count: 10,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],

      selectedItem: null,
      confirmation_type: null,
      reason_list: [],
      form: {
        reason_id: null,
        reason_other: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      search: "payments/search",
      filters: "payments/filters",
      activeTab: "payments/activeTab",
    }),

    headers() {
      return paymentTable[this.activeTab.key];
    },

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },
  watch: {
    page_count() {
      this.getPaymentList();
    },

    page_number() {
      this.getPaymentList();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.setSearch(String(query.search));
    }

    if (query.executive) {
      this.setFilters({ sales: Number(query.executive) });
    }

    if (query.date) {
      this.setFilters({ date: [String(query.date)] });
    }

    if (query.start_date && query.end_date) {
      this.setFilters({
        date: [String(query.start_date), String(query.end_date)],
      });
    }

    this.updateRoute();
  },

  mounted() {
    this.getPaymentList();
  },

  methods: {
    ...mapActions({
      setSearch: "payments/setSearch",
      setFilters: "payments/setFilters",
      showSnackbar: "app/showSnackbar",
      toggleDocumentViewer: "dialogs/toggleDocumentViewer",
      toggleCnfDialog: "dialogs/toggleConfirmationDialog",
      setCnfDialogBtnLoading: "dialogs/setConfirmationDialogBtnLoading",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getPaymentList();
    },

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.filters.sales) {
        query.executive = Number(this.filters.sales);
      }

      if (this.filters.date.length) {
        if (this.filters.date.length === 2) {
          query.start_date = String(this.filters.date[0]);
          query.end_date = String(this.filters.date[1]);
        } else {
          query.date = String(this.filters.date[0]);
        }
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    viewCase(id) {
      this.$router.push({ name: "caseDetail", params: { id } });
    },

    viewDocuments(item) {
      this.toggleDocumentViewer({
        show: true,
        items: [item.document],
        title: item.case.case_id,
        subtitle: item.case.service.name,
      });
    },

    openConfirmationDialog(item, type) {
      this.selectedItem = item;
      this.confirmation_type = type;
      if (type === "reject") this.getReasonList();
      this.toggleCnfDialog({ show: true, action: type });
    },

    confirmed() {
      if (this.confirmation_type === "approve") this.approve();
      if (this.confirmation_type === "reject") this.reject();
    },

    cancelled() {
      if (this.$refs.form) this.$refs.form.reset();
    },

    approve() {
      const onSuccess = () => {
        this.reloadData();
        this.toggleCnfDialog({ show: false });
      };

      const onFinally = () => {
        this.setCnfDialogBtnLoading({ loading: false });
      };

      let data = {
        payment_id: this.selectedItem.id,
      };

      return this.$request(this.$keys.POST, this.$urls.case.payment.approve, {
        data,
        onSuccess,
        onFinally,
      });
    },

    reject() {
      if (this.$refs.form.validate()) {
        const onSuccess = () => {
          this.reloadData();
          this.toggleCnfDialog({ show: false });
        };

        const onFinally = () => {
          this.setCnfDialogBtnLoading({ loading: false });
        };

        let data = {
          payment_id: this.selectedItem.id,
        };

        if (this.form.reason_id) data["reason_id"] = this.form.reason_id;
        if (this.form.reason_other)
          data["reason_other"] = this.form.reason_other;

        return this.$request(this.$keys.POST, this.$urls.case.payment.reject, {
          data,
          onSuccess,
          onFinally,
        });
      } else {
        this.setCnfDialogBtnLoading({ loading: false });
      }
    },

    getPaymentList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        payment_status: this.activeTab.key,
        search: this.search,
        executive_id: this.filters.sales,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.payment.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    getReasonList() {
      const onSuccess = (res) => {
        this.reason_list = res.data.data;
        this.reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      const params = {
        reason_type: "reject",
      };

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },

    downloadReceipt(payment_id) {
      this.showSnackbar({
        text: "Please wait, download is in progress.",
        color: "success",
      });
      let params = {
        payment_id: payment_id,
      };

      const onSuccess = (res) => {
        let filename = "receipt.pdf";
        if (res.headers["content-disposition"])
          [, filename] = res.headers["content-disposition"].split("filename=");
        saveAs(res.data, filename);
      };

      const onFailure = () => {
        this.showSnackbar({
          text: "Something went wrong",
          color: "error",
        });
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.case.payment.downloadReceipt,
        {
          params,
          onSuccess,
          onFailure,
          responseType: "blob",
        }
      );
    },
  },
};
</script>
