var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"20px"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"clearable":"","outlined":"","dense":"","placeholder":"Search name, email, or etc.","prepend-inner-icon":"mdi-magnify","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"auto","offset-y":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"value":_vm.dateRangeText,"placeholder":"Filter by Date Range","outlined":"","dense":"","append-icon":"mdi-calendar","clearable":"","readonly":""},on:{"click:clear":function($event){_vm.dateRange = []}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","range":"","scrollable":"","max":_vm.maxDate},on:{"input":_vm.onDateSelected},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),([_vm.$keys.SALES_HEAD, _vm.$keys.ACCOUNTS].includes(_vm.user.user_type))?_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","items":_vm.executive_list,"loading":_vm.executive_list_loading,"item-text":"name","item-value":"id","clearable":"","outlined":"","dense":"","placeholder":"Filter by Sales"},model:{value:(_vm.sales),callback:function ($$v) {_vm.sales=$$v},expression:"sales"}}):_vm._e()],1),_c('div',[_c('v-btn',{staticClass:"text-capitalize px-7 rounded-lg",attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":function($event){return _vm.toggleAddUpdateDialog({
            show: true,
            id: null,
            caseId: null,
            type: 'add',
          })}}},[_c('v-icon',{attrs:{"size":"16","left":""}},[_vm._v("mdi-plus")]),_vm._v("add payment ")],1)],1)]),_c('v-sheet',{staticClass:"rounded-b-0",attrs:{"elevation":"3"}},[_c('v-tabs',{attrs:{"height":"125px","next-icon":"mdi-chevron-right-circle","prev-icon":"mdi-chevron-left-circle","show-arrows":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(item,id){return _c('v-tab',{key:id,staticClass:"tab-width"},[_c('div',[_c('v-icon',{staticStyle:{"margin-top":"-25px","font-size":"45px"},attrs:{"size":"20","color":item.color}},[_vm._v("mdi-circle-small")]),_c('div',{staticClass:"tab-total",staticStyle:{"margin-top":"-10px"}},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{staticClass:"tab-name"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)])}),1)],1),_c('v-divider'),_c('paymentTable',{ref:"dataTable",on:{"reloadData":_vm.getListCount}}),_c('AddUpdatePayment',{on:{"submitted":_vm.reloadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }